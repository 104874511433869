import { render, staticRenderFns } from "./bni.vue?vue&type=template&id=31ff835b&scoped=true"
import script from "./bni.vue?vue&type=script&lang=js"
export * from "./bni.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31ff835b",
  null
  
)

export default component.exports