<template>
  <div class="container py-3">
    <panel-description
      :paymentMethodName="paymentMethodName"
      :virtualAccountNumber="virtualAccountNumber"
      :viewMybookingUrl="viewMybookingUrl"
      :price="price"
      :orderNumber="orderNumber"
    />
    <div class="mt-3 checkout--card">
      <div class="title--text">{{ $t('booking.guide.payment') + paymentMethodName }}</div>
      <div class="clearfix mb-20"></div>
      <v-tabs class="panel--tabs" v-model="tab" center-active>
        <v-tab href="#tab-0">
          <div>via ATM Mandiri / Bersama</div>
        </v-tab>
        <v-tab href="#tab-1">
          <div>via Internet Banking BNI</div>
        </v-tab>
        <v-tab href="#tab-2">
          <div>via Mobile Banking BNI</div>
        </v-tab>
        <v-tab href="#tab-3">
          <div>via Internet Banking Bank lain</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="tab-0">
          <ol class="mt-3">
            <li>{{ $t('booking.guide.bniBersama.msg1') }}</li>
            <li>{{ $t('booking.guide.bniBersama.msg2') }}</li>
            <li>{{ $t('booking.guide.bniBersama.msg3') }}</li>
            <li>{{ $t('booking.guide.bniBersama.msg4') }}</li>
            <li>
              {{ $t('booking.guide.bniBersama.msg5') }}
              {{ virtualAccountNumber }}
            </li>
            <li>
              {{ $t('booking.guide.bniBersama.msg6') }}
            </li>
            <li>
              {{ $t('booking.guide.bniBersama.msg7') }}
            </li>
            <li>{{ $t('booking.guide.bniBersama.msg8') }}</li>
          </ol>
        </v-tab-item>
        <v-tab-item value="tab-1">
          <ol class="mt-3">
            <li>{{ $t('booking.guide.bniInternet.msg1') }}</li>
            <li>{{ $t('booking.guide.bniInternet.msg2') }}</li>
            <li>{{ $t('booking.guide.bniInternet.msg3') }}</li>
            <li>{{ $t('booking.guide.bniInternet.msg4') }}</li>
            <li>
              {{ $t('booking.guide.bniInternet.msg5') }} {{ virtualAccountNumber }}.
              {{ $t('booking.guide.bniInternet.msg6') }}
            </li>
            <li>{{ $t('booking.guide.bniInternet.msg7') }}</li>
            <li>{{ $t('booking.guide.bniInternet.msg8') }}</li>
            <li>{{ $t('booking.guide.bniInternet.msg9') }}</li>
          </ol>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <ol class="mt-3">
            <li>
              {{ $t('booking.guide.bniMobile.msg1') }}
            </li>
            <li>{{ $t('booking.guide.bniMobile.msg2') }}</li>
            <li>{{ $t('booking.guide.bniMobile.msg3') }}</li>
            <li>
              {{ $t('booking.guide.bniMobile.msg4') }} {{ virtualAccountNumber }}
              {{ $t('booking.guide.bniMobile.msg5') }}.
            </li>
            <li>{{ $t('booking.guide.bniMobile.msg6') }}</li>
            <li>{{ $t('booking.guide.bniMobile.msg7') }}</li>
            <li>{{ $t('booking.guide.bniMobile.msg8') }}</li>
          </ol>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <ol class="mt-3">
            <li>{{ $t('booking.guide.bniOther.msg1') }}</li>
            <li>{{ $t('booking.guide.bniOther.msg2') }}</li>
            <li>
              {{ $t('booking.guide.bniOther.msg3') }} {{ virtualAccountNumber }}
              {{ $t('booking.guide.bniOther.msg4') }}
            </li>
            <li>
              {{ $t('booking.guide.bniOther.msg5') }}
            </li>
            <li>
              {{ $t('booking.guide.bniOther.msg6') }}
            </li>
            <li>{{ $t('booking.guide.bniOther.msg7') }}</li>
          </ol>
        </v-tab-item>
      </v-tabs-items>
      <div class="col-md-12 col-xs-12 col-sm-12 mt-5">
        <!--        <router-link-->
        <!--          :to="`/mybooking/detail?type=BOOKING&uuid=${bookingOrder.uuid}`"-->
        <!--          class="btn btn-primary&#45;&#45;outline btn-block"-->
        <!--          >{{ $t('thankyouPage.viewBookButton') }}-->
        <!--        </router-link>-->
        <router-link :to="viewMybookingUrl" class="btn btn-primary--outline btn-block"
          >{{ $t('general.btn.viewBook') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const PanelDescription = () => import('@/components/booking/how-to-pay/panel-description.vue');
export default {
  name: 'bni',
  components: {
    PanelDescription,
  },
  props: ['paymentMethodName', 'price', 'virtualAccountNumber', 'viewMybookingUrl', 'orderNumber'],
  data() {
    return {
      tab: 'tab-0',
    };
  },
  computed: {
    ...mapState({
      bookingOrder: (state) => state.v2.booking.bookingOrder,
    }),
  },
};
</script>

<style scoped></style>
